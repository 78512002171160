import { usePageField } from '@voix/composables/usePageField'
import { usePageStore } from '@voix/store/pageStore'

export function useAtlantisHead() {
  const pageField = usePageField()
  const hideChatButton = pageField.get('hideChatButton', false)
  const pageStore = usePageStore()
  const siteId = pageStore.currentPage?.site_id
  const route = useRoute()
  const config = useRuntimeConfig()

  useHead({
    meta: [
      { name: 'google-site-verification', content: 'On5W12ocT5q1YOGKvHwdxXtx88stk-oGO-45IDEWUDw' },
      { charset: 'utf-8' },
      { 'http-equiv': 'X-UA-Compatible', 'content': 'IE=edge' },
    ],
    script: [
      { type: 'text/javascript', innerHTML: 'function OptanonWrapper() {}' },
      { type: 'text/javascript', innerHTML: `var mothershipApiKey = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNmI2ZWE0ZmY4NWE1YTA3ZDg0NmU3ZTU5Yjc4N2JlZDExZTg5NTBiYWI0ZjhiNzEyODM4MTk5MTc1MWI5MTA5N2RhN2ZmZDc3ODg1ODczOTkiLCJpYXQiOjE3MjU5NzE5MDUuMDI0NzY5LCJuYmYiOjE3MjU5NzE5MDUuMDI0Nzc0LCJleHAiOjE3NTc1MDc5MDUuMDIxNDkyLCJzdWIiOiI3Iiwic2NvcGVzIjpbImxvZ3MiXX0.sk0BOK_37kh0zMf-InImnt0HrVcyi_MoCQla_ABtEC8CDLWBtvw9__YelBvQBXy2CKHtbktniJHAcDyftJCPj62zlDAeKRGqQGZOvvV2Qs-7pfq4dI6V1giqX09XDKAzhU6LPK1dTGCYnxX5BUpTRq_cnvfAnWIt1Igj5Vyi9Sw";
          window.MothershipConfig = {
            apiKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNmI2ZWE0ZmY4NWE1YTA3ZDg0NmU3ZTU5Yjc4N2JlZDExZTg5NTBiYWI0ZjhiNzEyODM4MTk5MTc1MWI5MTA5N2RhN2ZmZDc3ODg1ODczOTkiLCJpYXQiOjE3MjU5NzE5MDUuMDI0NzY5LCJuYmYiOjE3MjU5NzE5MDUuMDI0Nzc0LCJleHAiOjE3NTc1MDc5MDUuMDIxNDkyLCJzdWIiOiI3Iiwic2NvcGVzIjpbImxvZ3MiXX0.sk0BOK_37kh0zMf-InImnt0HrVcyi_MoCQla_ABtEC8CDLWBtvw9__YelBvQBXy2CKHtbktniJHAcDyftJCPj62zlDAeKRGqQGZOvvV2Qs-7pfq4dI6V1giqX09XDKAzhU6LPK1dTGCYnxX5BUpTRq_cnvfAnWIt1Igj5Vyi9Sw"
          };` },
      { src: `/js-libs/mothership-js.lib.js` },
      { src: `/js-libs/mothership-analytics.lib.js` },
      {
        src: 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
        charset: 'UTF-8',
        id: 'otprivacy-notice-script',
        body: true,
        settings: 'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9',
      },
      { src: `//fast.wistia.com/assets/external/E-v1.js`, body: true },
      { type: 'text/javascript', body: true, innerHTML: `
          OneTrust.NoticeApi.Initialized.then(function() {
            OneTrust.NoticeApi.LoadNotices([
                "https://privacyportal-cdn.onetrust.com/1a83e5ad-9ea4-49fa-91d0-295c0bddffd1/privacy-notices/052bcd62-6e08-47c9-9723-695a102136ae.json"
            ]);
        });` },
    ],
  })

  // We pass nochrome=true in to disable all types of things
  // to minimize the fluff that is loaded on the page. This
  // is used for things like the mobile app or iframes.
  if (!route.query.nochrome && !hideChatButton.value) {
    useHead({
      script: [
        {
          src: 'https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js',
          body: true,
        },
        { type: 'text/javascript', body: true, innerHTML: `
            var options = {
              "rootUrl": "https://app.five9.com/consoles/",
              "type": "chat",
              "title": "Atlantis Live Chat",
              "tenant": "atlantisparadise.com.",
              "profiles": ["Select", "Chat - Planning Trip", "Chat - On-Property"],
              "profileLabel": "For guests on property, we are available to chat from 10am-10pm ET.",
              "showProfiles": true,
              "autostart": true,
              "theme": "https://www.atlantisbahamas.com/five-nine/chat-theme.css",
              "surveyOptions": {
                  "showComment": false,
                  "requireComment": false
              },
              "fields": {
                  "name": {
                      "value": "",
                      "show": true,
                      "label": "Name*"
                  },
                  "email": {
                      "value": "",
                      "show": true,
                      "label": "Email*"
                  },
                  "Contact.number1": {
                      "value": "",
                      "show": true,
                      "label": "Phone"
                  },
                  "question": {
                      "value": "",
                      "show": true,
                      "label": "How may we help you?*"
                  },
              },
              "playSoundOnMessage": true,
              "allowCustomerToControlSoundPlay": false,
              "showEmailButton": false,
              "hideDuringAfterHours": true,
              "useBusinessHours": true,
              "showPrintButton": true,
              "allowUsabilityMenu": false,
              "enableCallback": false,
              "callbackList": "",
              "allowRequestLiveAgent": false
          };
      
          if (typeof window !== 'undefined' && window.Five9SocialWidget) {
              Five9SocialWidget.addWidget(options);
          }` },
      ],
    })

    if (typeof document !== 'undefined') {
      setTimeout(() => {
        try {
          document.querySelectorAll('a').forEach((el) => {
            el.setAttribute('target', '_top')
          })
        }
        catch (e) {
          console.error(e)
        }
      }, 3000)
    }
  }

  if (config.public.atlantisEnableGTM === 'true') {
    if (siteId === 1) {
      useServerHead({
        noscript: [
          {
            id: 'google-tm-noscript',
            innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-9KCL" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            body: true,
          },
        ],
        script: [
          {
            id: 'google-tm-head',
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-9KCL')`,
          },
        ],
      })

      useHead({
        script: [
          { type: 'text/javascript', innerHTML: 'var monetateT = new Date().getTime();' },
          {
            src: `//se.monetate.net/js/2/a-f67e2ee1/p/atlantisbahamas.com/entry.js`,
          },
        ],
      })
    }

    if (siteId === 2) {
    //  <!-- OneTrust Cookies Consent Notice start for reefownersatlantis.com -->
      useHead({
        script: [
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': 'f21a57f4-1c67-4280-a845-9ee2736622a6' },
          {
            src: `https://www.googletagmanager.com/gtag/js?id=GTM-KNDXFLP`,
            async: true,
          },
        ],
      })
    }

    if (siteId === 3) {
    //  <!-- OneTrust Cookies Consent Notice start for careers.atlantisbahamas.com -->
      useHead({
        script: [
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': '9023922a-3af8-4937-bade-611bbbfd2e8a' },
        ],
      })
    }

    if (siteId === 4) {
    //  <!-- OneTrust Cookies Consent Notice start for myatlantisvacation.com -->
      useHead({
        script: [
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': 'da4929df-03ab-4255-b080-d7cef8d25b58' },
          {
            src: `https://www.googletagmanager.com/gtag/js?id=GTM-9KCL`,
            async: true,
          },
        ],
      })
    }

    if (siteId === 5) {
    //  <!-- OneTrust Cookies Consent Notice start for oceanclubgolfcourse.com -->
      useHead({
        script: [
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': 'a092ff9e-ec2e-42c6-a7cb-3b457434f15e' },
        ],
      })
    }
  }
}
